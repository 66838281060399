// Here you can add other styles
td:nth-of-type(6) .action-buttons {
  display: flex;
  gap: 10px;
  margin-top: 8px;
}

table {
  overflow: scroll;
}

//Drag and Drop
#form-file-upload {
  height: 16rem;
  //width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.table-sameday {

  td {
    padding-top: 30px;
  }

  &::after {
    content: 'Same Day';
    text-transform: uppercase;
    width: 100%;
    color: white;
    background-color: #B52C75;
    left: 0;
    position: absolute;
    text-align: center;
    font-family: var(--cui-body-font-family);
    font-weight: bold;
    letter-spacing: 0.5px;
  }

}

.action-buttons {
  gap: 5px;
}

.action-button__cropping {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

//override customer date picker secondary color
.date-picker-ranges .btn-ghost-secondary {
  color: #B52C75 !important;
}

.react-select-invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e55353;
}